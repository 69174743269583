import { useState } from 'react';
import { styled } from '@mui/material';
import { format } from 'date-fns';

const bootFlags = [
  //section 1
  { BOOT_BY_MOTION: 0x00000001 },
  { BOOT_BY_KEY: 0x00000002 },
  { BLE_FAIL: 0x00000004 },
  { QSPI_FLASH_FAIL: 0x00000008 },
  //section 2
  { GPS_FAIL: 0x00000010 },
  { WIFI_FAIL: 0x00000020 },
  { ACCELEROMETER_FAIL: 0x00000040 },
  { HR_COMM_FAIL: 0x00000080 },
  //section 3
  { HR_DETECTOR_FAIL: 0x00000100 },
  { HR_ACCELEROMETER_FAIL: 0x00000200 },
  { HR_OSCILLATOR_FAIL: 0x00000400 },
  { TEMPERATURE_FAIL: 0x00000800 },
  //section 4
  { PRESSURE_FAIL: 0x00001000 },
  { BATTERY_FAIL: 0x00002000 },
  { SDCARD_FAIL: 0x00004000 },
  { NBIOT_FAIL: 0x00008000 },
  //section 5
  { CURRENT_SENSE_FAIL: 0x00010000 },
  { UWB_INTERFACE_FAIL: 0x00020000 },
  { BOOT_BY_TIMER: 0x00040000 },
  { BOOT_BY_WATCHDOG: 0x00080000 },
  { LED_BAR_INTERFACE_FAIL: 0x00100000 },
  { LORA_FAIL: 0x00200000 },
];

const shutdownFlags = [
  { KEY: 0 },
  { Stationary: 1 },
  { Low_Battery: 2 },
  { Charging: 3 },
  { Dastrack_No_power: 4 },
  { Device_Locked: 5 },
];

const deviceStatusFlags = [
  { Device_is_Put_On: 0x01 },
  { UWB_Detector_Is_Powered: 0x02 },
  { UWB_Detector_Is_Operating: 0x04 },
  { Recess_or_Sleep_Beacon_Detection_enabled: 0x08 },
  { In_Recess_Area: 0x10 }, //0 = Not in Recess Area   1 = In Recess Area
  { In_Sleep_Area: 0x20 }, //0 = Not in Sleep Area     1= In Sleep Area
  { In_Maintenance: 0x40 }, //0 = Not in Maintenance Area	1 = In Maintenance Area
  { Device_locked: 0x80 },
];

const Container = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  &:nth-of-type(even) {
    background: ${({ theme }) => theme.color.secondary.$40};
  }

  &:hover {
    background: ${({ theme }) => theme.color.secondary.$60};
  }

  & pre {
    cursor: text;
    width: 800px;
  }
`;

interface ReportItemProps {
  data: any;
  filterRegex?: RegExp;
}

const ReportItem: React.FC<ReportItemProps> = ({ data, filterRegex }) => {
  const [isExpand, setIsisExpand] = useState(false);
  let rawDataText = JSON.stringify(data, null, 2);

  // Convert Beacon Id to readable message consisted with minor and major
  if (
    !Array.isArray(data?.shadow?.dataPoint?.beacons[0]?.id) &&
    data?.shadow?.dataPoint?.beacons[0]?.id
  ) {
    data.shadow.dataPoint.rawBeacons = data.shadow.dataPoint.beacons;
    data.shadow.dataPoint.beacons = data.shadow.dataPoint.beacons.map((d) => ({
      ...d,
      id: [
        d.id
          .toString(16)
          .toUpperCase()
          .padStart(8, '0')
          .match(/.{4}/g)
          .map((d2, index) =>
            !index ? { minor: parseInt(d2, 16) } : { major: parseInt(d2, 16) },
          ),
      ],
    }));
  }

  // convert bootresult to readable text
  if (
    data?.shadow?.dataPoint?.bootResult &&
    !Array.isArray(data?.shadow?.dataPoint?.bootResult)
  ) {
    const tempFlags = [] as string[];
    const tempResult = data?.shadow?.dataPoint?.bootResult;
    for (const obj of bootFlags) {
      for (const [key, value] of Object.entries(obj)) {
        if (value & data?.shadow?.dataPoint?.bootResult) {
          tempFlags.push(key);
        }
      }
    }
    data.shadow.dataPoint.bootResult = tempFlags as string[];
    data.shadow.dataPoint.rawBootResult = tempResult as string;
  }

  // convert device status to readable text
  if (
    !Array.isArray(data?.shadow?.dataPoint?.deviceStatus) &&
    data?.shadow?.dataPoint?.deviceStatus
  ) {
    const tempFlags = [] as string[];
    const tempResult = data.shadow.dataPoint.deviceStatus;
    for (const obj of deviceStatusFlags) {
      for (const [key, value] of Object.entries(obj)) {
        if (value & data.shadow.dataPoint.deviceStatus) {
          tempFlags.push(key);
        }
      }
    }
    data.shadow.dataPoint.deviceStatus = tempFlags;
    data.shadow.dataPoint.rawDeviceStatus = tempResult;
  }

  // convert shutdown to readable text
  if (
    data?.shadow?.dataPoint?.shutdown !== 256 &&
    !Array.isArray(data?.shadow?.dataPoint?.shutdown) &&
    data?.shadow?.dataPoint?.shutdown
  ) {
    data.shadow.dataPoint.rawShutdown = data.shadow.dataPoint.shutdown;
    const tempFlags = [] as string[];
    for (const obj of shutdownFlags) {
      for (const [key, value] of Object.entries(obj)) {
        console.log(
          'value',
          value,
          key,
          data.shadow.dataPoint.shutdown,
          'result',
          value === data.shadow.dataPoint.shutdown,
        );
        if (value === data.shadow.dataPoint.shutdown) {
          tempFlags.push(key);
        }
      }
    }
    data.shadow.dataPoint.shutdown = tempFlags;
  }
  // convert UWB ID to hax format
  if (data?.metadata?.uwbId && data?.metadata?.uwbId.length === 6) {
    data.metadata.rawUwbId = data.metadata.uwbId;
    data.metadata.uwbId = data.metadata.uwbId.toString(16).padStart(6, '0');
  }

  if (filterRegex) {
    const lines = rawDataText.split('\n');
    let continueFlag = false;
    let closureCount = 0;
    rawDataText = '';

    lines.forEach((line) => {
      if (filterRegex.test(line) || continueFlag) {
        const openingBrackets = (line.match(/[[{]/g) || []).length;
        const closingBrackets = (line.match(/[\]}]/g) || []).length;

        closureCount += openingBrackets - closingBrackets;
        continueFlag = closureCount !== 0;

        rawDataText += line + '\n';
      }
    });
  }

  let date: Date;
  if (data.timestamp) {
    date = new Date(data.timestamp);
  } else if (data?.shadow?.dataPoint?.timestamp) {
    date = new Date(data.shadow.dataPoint.timestamp);
  } else {
    return null;
  }

  return (
    <Container onClick={() => setIsisExpand(!isExpand)}>
      <div>{format(date, 'yyyy-MM-dd HH:mm:ss')}</div>
      <pre
        style={{ height: isExpand ? 300 : 60 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {rawDataText}
      </pre>
    </Container>
  );
};

export default ReportItem;
