import { styled } from '@mui/material';
import React from 'react';
import Input from '../../../../components/Input';

const Container = styled('div')``;

interface AreaLimitProps {
  areaLimit: number | undefined;
  onChange: (areaLimit: number) => void;
}

const AreaLimit: React.FC<AreaLimitProps> = ({ areaLimit, onChange }) => {
  return (
    <Container>
      <label>Area Limit</label>
      <Input
        sx={{
          width: '100%',
          display: 'block',
          placeholderShown: 'true',
        }}
        value={areaLimit ?? ''}
        placeholder={'Default : 10 '}
        onChange={(e) => {
          onChange(Number(e.currentTarget.value));
        }}
      ></Input>
    </Container>
  );
};

export default AreaLimit;
