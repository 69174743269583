import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { CheckboxLight } from '@beeinventor/dasiot-react-component-lib';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material';

import { DasEndpoint, Device, Locator } from '../types/Device';

import { mapToDeviceUrl, parseDasId } from '../utils/common';

import CircleEditSvgIcon from './SvgIcon/CircleEditSvgIcon';

const Container = styled('div')(({ theme }) => {
  return {
    margin: '8px 0',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginTop: 0,
    },

    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '& > .hardware-item': {
        display: 'flex',
        alignItems: 'center',
        padding: '11px 32px 11px 16px',
        background: '#fff',
        '& > .number': {
          minWidth: '64px',
        },
        '& > .das-type': {
          width: '2.5rem',
          height: '2.5rem',
          marginRight: '24px',
        },
        '& > .dasId': {
          fontFamily: '"Noto Sans Mono", "monospace"',
        },
        '& > .email': {
          flex: '1 1 auto',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          margin: '0 16px',
        },
        '& > .collisionId': {
          flex: '0 0 167px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          margin: '0 16px',
        },
        '& > .uwbId': {
          flex: '0 0 167px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          margin: '0 16px',
        },
        '& > .project': {
          flex: '1 1 auto',
          minWidth: '100px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          margin: '0 16px',
        },
      },
      '& > .detail': {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 32px 20px 64px',
        background: theme.color.secondary.$40,
        '& > .list': {
          flex: '1 1 auto',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          alignCOntent: 'flex-start',
          gap: '8px 0',
          '& > .metadata-property': {
            color: theme.color.secondary.$80,
            '&:after': {
              display: 'inline-block',
              width: '1px',
              height: '24px',
              content: '""',
              margin: '0 24px',
              background: '#fff',
              verticalAlign: 'middle',
            },
            '& > .name': {
              display: 'inline-block',
              color: theme.color.secondary.$100,
              marginRight: '1rem',
            },
          },
        },
        '& > .operation': {
          '& > button': {
            cursor: 'pointer',
            display: 'block',
            background: '#3E3E3E',
            borderRadius: '50%',
            padding: 0,
            border: 'none',
            '& > svg': {
              display: 'block',
              width: '32px',
              height: '32px',
              color: 'white',
            },
          },
        },
      },
    },

    '& .expand-button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
    },

    '& .edit-button': {
      cursor: 'pointer',
      '& svg': {
        display: 'block',
      },
    },
  };
});

interface HardwareItemProps {
  number: number;
  data: DasEndpoint | Locator;
  displayExpandButton?: boolean;
  isExpand?: boolean;
  isSelected: boolean;
  editable?: boolean;
  onSelect: (data: Device, value: boolean) => void;
  onExpand?: (isExpand: boolean) => void;
  onEdit?: () => void;
}

const HardwareItem = forwardRef<HTMLDivElement, HardwareItemProps>(
  (
    {
      number,
      data,
      displayExpandButton = false,
      isExpand = false,
      isSelected,
      editable,
      onSelect,
      onExpand,
      onEdit,
    },
    ref,
  ) => {
    const dasEndpointType = parseDasId(data.dasId);

    const handleOnChange = (checked: boolean) => {
      onSelect(data, checked);
    };

    return (
      <Container ref={ref}>
        <Link
          to={`/devices/${data.dasId}/detail`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="hardware-item">
            <span className="number">{`${number}.`}</span>
            <div
              className="expand-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onExpand?.(!isExpand);
              }}
            >
              {displayExpandButton && (
                <>
                  {isExpand ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </>
              )}
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <CheckboxLight onChange={handleOnChange} checked={isSelected} />
            </div>
            <img className="das-type" src={mapToDeviceUrl(dasEndpointType)} />
            <span className="dasId">{data.dasId}</span>
            <div className="email" title={data.owner?.email}>
              {data.owner?.email}
            </div>
            {!('coordinates' in data) && data.type === 'das_collision_tag' && (
              <div className="collisionId" title={data.metadata.collisionId}>
                Collision ID: {data.metadata.collisionId}
              </div>
            )}
            {!('coordinates' in data) && data.type === 'das_collision_tag' && (
              <div className="uwbId" title={data.metadata.uwbId}>
                UWB ID: {data.metadata.uwbId}
              </div>
            )}
            <div className="project" title={data.project?.name}>
              {data.project?.name}
            </div>
            {editable && (
              <div
                className="edit-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEdit?.();
                }}
              >
                <CircleEditSvgIcon />
              </div>
            )}
          </div>
        </Link>
      </Container>
    );
  },
);

HardwareItem.displayName = 'HardwareItem';

export default HardwareItem;
