import { styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';

const Container = styled('div')`
  & > label {
    padding-top: 15px;
    color: var(--Secondary-60, #a1a1a1);
    font-family: 'Noto Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  & > .container-user {
    display: flex;
    margin-bottom: 10px;

    & > .text-name {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      color: var(--Secondary-60, #a1a1a1);
      font-family: 'Noto Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

interface UserLimitProps {
  userLimit: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const UserLimit: React.FC<UserLimitProps> = ({ userLimit, onChange }) => {
  const [localUserLimit, setLocalUserLimit] = useState<{
    [propertyName: string]: any;
  }>({
    admin: userLimit?.admin,
    viewer: userLimit?.viewer,
    supervisor: userLimit?.supervisor,
  });

  useEffect(() => {
    setLocalUserLimit({
      admin: userLimit?.admin,
      viewer: userLimit?.viewer,
      supervisor: userLimit?.supervisor,
    });
  }, [userLimit]);

  return (
    <Container>
      <label>Admin limit</label>
      <Input
        sx={{
          width: '100%',
          display: 'block',
          placeholderShown: 'true',
        }}
        value={localUserLimit.admin}
        placeholder={'Default : 2 '}
        onChange={(e) => {
          setLocalUserLimit({
            ...localUserLimit,
            admin: Number(e.currentTarget.value),
          });
          onChange('admin', e.currentTarget.value);
        }}
      />
      <label>Supervisor limit</label>
      <Input
        sx={{
          width: '100%',
          display: 'block',
          placeholderShown: 'true',
        }}
        value={localUserLimit.supervisor}
        placeholder={'Default : 10 '}
        onChange={(e) => {
          setLocalUserLimit({
            ...localUserLimit,
            supervisor: Number(e.currentTarget.value),
          });
          onChange('supervisor', e.currentTarget.value);
        }}
      />
      <label>Viewer limit</label>
      <Input
        sx={{
          width: '100%',
          display: 'block',
          placeholderShown: 'true',
        }}
        value={localUserLimit.viewer}
        placeholder={'Default : 20 '}
        onChange={(e) => {
          setLocalUserLimit({
            ...localUserLimit,
            viewer: Number(e.currentTarget.value),
          });
          onChange('viewer', e.currentTarget.value);
        }}
      />
    </Container>
  );
};

export default UserLimit;
