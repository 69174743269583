import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useMemo } from 'react';
import { getProjects, getProjectSettings } from '../../../apis/projectApi';
import { useAppSelector } from '../../../hooks';
import ProjectItem from './ProjectItemSetting';
import { ProjectSetting } from '../../../types';

const Container = styled('div')`
  height: calc(100vh - 74px);
  padding: 20px 48px;
  overflow: auto;
`;

const GeneralSettingPage = () => {
  const { text } = useAppSelector((store) => store.system.filter);
  const { data: projects } = useQuery({
    queryKey: ['get-projects'],
    queryFn: async () => {
      const res = await getProjects();
      return res.data.data || [];
    },
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: projectSettingMap } = useQuery({
    queryKey: ['project-setting'],
    queryFn: async () => {
      const res = await getProjectSettings();
      const rawSettings = res.data.data;

      const localProjectSettingMap: { [index: string]: ProjectSetting } = {};
      rawSettings.forEach((ps) => {
        localProjectSettingMap[ps.projectId] = ps; // or ps.key, adjust as needed
      });

      return localProjectSettingMap;
    },
    refetchOnWindowFocus: false,
  });

  const projectItems = useMemo(() => {
    if (projects.length > 0) {
      return projects
        .filter((project) => {
          if (text === '') return true;
          return project.name.includes(text);
        })
        .map((project, index) => {
          const projectSetting = projectSettingMap?.[project.id];
          return (
            <ProjectItem
              key={`project-item-${project.id}`}
              order={index + 1}
              project={project}
              projectSetting={projectSetting}
            />
          );
        });
    }
    return null;
  }, [projects, text, projectSettingMap]);

  return (
    <>
      <Container>{projectItems}</Container>
      <Outlet />
    </>
  );
};

export default GeneralSettingPage;
