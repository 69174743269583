import type { SVGProps } from 'react';

const WebhookSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M13.6 20.6H20.656C20.98 20.228 21.46 20 22 20C22.996 20 23.8 20.804 23.8 21.8C23.8 22.796 22.996 23.6 22 23.6C21.472 23.6 20.992 23.372 20.656 23H15.88C15.328 25.736 12.904 27.8 10 27.8C6.688 27.8 4 25.112 4 21.8C4 18.896 6.064 16.472 8.8 15.92V18.404C7.408 18.896 6.4 20.24 6.4 21.8C6.4 23.78 8.02 25.4 10 25.4C11.98 25.4 13.6 23.78 13.6 21.8V20.6ZM16.6 7.4C18.58 7.4 20.2 9.02 20.2 11H22.6C22.6 7.688 19.912 5 16.6 5C13.288 5 10.6 7.688 10.6 11C10.6 12.716 11.32 14.252 12.46 15.344L9.64 20.024C8.824 20.192 8.2 20.924 8.2 21.8C8.2 22.796 9.004 23.6 10 23.6C10.996 23.6 11.8 22.796 11.8 21.8C11.8 21.608 11.776 21.428 11.716 21.26L15.772 14.504C14.188 14.132 13 12.704 13 11C13 9.02 14.62 7.4 16.6 7.4ZM22 18.2C21.232 18.2 20.524 18.44 19.936 18.848L16.276 12.764C15.436 12.62 14.8 11.888 14.8 11C14.8 10.004 15.604 9.2 16.6 9.2C17.596 9.2 18.4 10.004 18.4 11C18.4 11.18 18.376 11.348 18.328 11.516L20.956 15.896C21.292 15.836 21.64 15.8 22 15.8C25.312 15.8 28 18.488 28 21.8C28 25.112 25.312 27.8 22 27.8C19.78 27.8 17.836 26.588 16.804 24.8H20.008C20.584 25.184 21.268 25.4 22 25.4C23.98 25.4 25.6 23.78 25.6 21.8C25.6 19.82 23.98 18.2 22 18.2Z"
      fill="currentColor"
    />
  </svg>
);
export default WebhookSvgIcon;
