import type { SVGProps } from 'react';

const SafieSvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      d="M28 6.84846C28 5.28987 26.0665 4 22.9514 4C18.3862 4 16.2378 6.36476 14.9488 6.95595C14.7877 7.00969 14.6803 6.84846 14.8414 6.74097C15.9693 5.98855 16.023 4.91366 16.0767 4.42996C16.0767 4.26872 15.9156 4.21498 15.8082 4.32247C14.358 5.61233 13.4987 5.77357 12.0486 5.77357C8.23525 5.82731 5.49611 7.81586 5.65723 10.3419C5.65723 10.6106 5.60353 10.7181 5.38869 10.8256C3.83114 11.7392 3.5626 13.7278 4.69048 14.6952C4.90531 14.9101 5.01273 14.8564 4.95902 14.5877C4.69048 13.6203 5.28127 13.1366 5.60353 13.1903C6.03319 13.2441 5.17386 16.2 8.5575 17.0062C8.77233 17.0599 8.87975 17.0062 8.82604 17.5436C8.45008 21.6282 9.73909 25.9278 13.9821 28.4537C16.6675 30.0661 19.2992 27.9163 16.8286 24.5304C18.3862 24.4229 19.4066 23.1868 19.6752 21.7894C20.3197 22.1119 21.0179 22.3269 21.8235 22.3269C24.4015 22.3269 26.4425 20.2846 26.4425 17.7048C26.4425 15.9313 25.422 14.3727 23.9719 13.6203C25.2072 12.5454 25.3146 10.3419 24.5089 9.37445C26.6573 9.10573 28 8.08458 28 6.84846ZM10.7595 9.3207C11.1892 9.69692 11.5115 10.2881 11.5115 10.933C11.5115 12.1154 10.5447 13.0828 9.36313 13.0828C8.18154 13.0828 7.21478 12.1154 7.21478 10.933C7.21478 10.7718 7.21478 10.6643 7.26849 10.5568C7.26849 10.4493 7.3222 10.4493 7.42962 10.3956C7.69816 10.3419 7.9667 10.2344 8.18154 10.1806C8.23525 10.1806 8.23525 10.2344 8.23525 10.2344C8.12783 10.4493 8.02041 10.6643 8.02041 10.933C8.02041 11.6855 8.61121 12.2767 9.36313 12.2767C10.115 12.2767 10.7058 11.6855 10.7058 10.933C10.7058 10.3419 10.3299 9.85815 9.8465 9.69692C9.79279 9.69692 9.79279 9.64317 9.8465 9.58943C10.115 9.48194 10.3299 9.37445 10.5984 9.26696C10.6521 9.26696 10.7058 9.26696 10.7595 9.3207Z"
      fillRule="evenodd"
      fill="#3E3E3E"
      clipRule="evenodd"
    />
  </svg>
);
export default SafieSvgIcon;
