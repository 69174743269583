import { FormEvent, forwardRef, ReactNode } from 'react';
import { InputProps as MuiInputProps, styled, SxProps } from '@mui/material';

const Container = styled('div')(({ theme }) => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    backgroundColor: theme.color.box_bbg,
    '&[data-error="true"]': {
      border: '1px solid #FF6B00',
    },
    '& > input': {
      width: '100%',
      fontSize: '1rem',
      color: theme.color.secondary.$100,
      backgroundColor: theme.color.box_bbg,
      border: 'none',
      borderRadius: '4px',
      padding: '11.5px 16px',
      outlineColor: theme.color.primary.$100,
      colorScheme: 'none',
      '&:read-only': {
        outline: 'none',
        color: '#3E3E3E80',
      },
    },
  };
});

const Message = styled('div')`
  text-align: center;
`;

const ErrorMessage = styled('div')(() => {
  return {
    color: '#FF6B00',
    fontSize: '0.75rem',
  };
});

interface InputProps {
  sx?: SxProps;
  type?: MuiInputProps['type'];
  defaultValue?: string;
  value?: string | number;
  readOnly?: boolean;
  rightIcon?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  message?: string;
  error?: boolean;
  errorMessage?: string;
  min?: string | number;
  max?: string | number;
  placeholder?: string;
  accept?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      sx,
      type,
      defaultValue,
      value,
      readOnly,
      rightIcon,
      onBlur,
      onChange,
      message,
      error = false,
      errorMessage,
      min,
      max,
      placeholder,
      accept,
    },
    ref,
  ) => (
    <>
      <Container sx={sx} data-error={error}>
        <input
          ref={ref}
          type={type}
          defaultValue={defaultValue}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          min={min}
          max={max}
          placeholder={placeholder}
          accept={accept}
        />
        {rightIcon}
      </Container>
      {message && <Message>{message}</Message>}
      <ErrorMessage className="error-message">{errorMessage}</ErrorMessage>
    </>
  ),
);

Input.displayName = 'Input';

export default Input;
