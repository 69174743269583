import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { ProjectSetting } from '../../../types';
import { Project } from '../../../types/Project';

const Container = styled(Link)`
  display: grid;
  align-items: center;
  min-height: 72px;
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.secondary.$100};
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 11px 24px 11px 16px;
  margin: 8px 0;
  gap: 24px;
  text-decoration: none;
  grid-template-columns: 30px 150px 128px 128px 128px 1fr;

  & > div {
    &:first-of-type {
      min-width: 30px;
      font-weight: 500;
      text-align: center;
    }
    &:nth-of-type(2) {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:nth-of-type(5) {
      border-right: 1px solid black;
    }
  }
`;

interface ProjectProps {
  order: number;
  project: Project;
  projectSetting?: ProjectSetting;
}

const ProjectItemSetting: React.FC<ProjectProps> = ({
  order,
  project,
  projectSetting,
}) => {
  return (
    <Container to={`${project.id}/info`}>
      <div>{order}</div>
      <div className="project-name" title={project.name}>
        {project.name}
      </div>
      {projectSetting?.userLimit?.admin && (
        <div>Admin {projectSetting?.userLimit?.admin}</div>
      )}
      {projectSetting?.userLimit?.supervisor && (
        <div>Supervisor {projectSetting?.userLimit?.supervisor}</div>
      )}
      {projectSetting?.userLimit?.viewer && (
        <div>Viewer {projectSetting?.userLimit?.viewer}</div>
      )}
      {projectSetting?.areaLimit && (
        <div>Area {projectSetting?.areaLimit || 10}</div>
      )}
    </Container>
  );
};

export default ProjectItemSetting;
