import { Model, PagedData, ProjectSetting, Response } from '../types';
import {
  DasEndpointType,
  FeatureListType,
  OtherFeatureType,
  SSSSFeatureType,
} from '../types/Device';
import { Project, ProjectFeature } from '../types/Project';

import { dsmOpsAxios } from '.';

interface GetProjectsParams {
  id?: string;
  name?: string;
  owenerId?: string;
}

export const getProjects = (params?: GetProjectsParams) => {
  return dsmOpsAxios.get<Response<Project[]>>(`/v1/projects`, { params });
};

export const get3dAssets = (projectId: string) => {
  return dsmOpsAxios.get<Response<Model[]>>(
    `/v1/projects/${projectId}/3d-assets`,
  );
};

interface UpdateAssetsData {
  projectId: string;
  assetId: string;
  data: Pick<
    Model,
    | 'name'
    | 'coordinates'
    | 'rotation'
    | 'scale'
    | 'rotationTuneParams'
    | 'heightTune'
  >;
}

export const updateAsset = (data: UpdateAssetsData) => {
  return dsmOpsAxios.patch<Response<Model>>(
    `/v1/projects/${data.projectId}/3d-assets/${data.assetId}`,
    data.data,
  );
};

interface UploadAssetData {
  projectId: string;
  data: {
    name: string;
    ifcFile?: File;
    glbFile: File;
  };
}

export const uploadAsset = (data: UploadAssetData) => {
  const formData = new FormData();
  if (data.data.ifcFile) {
    formData.append('ifc', data.data.ifcFile);
  }
  formData.append('gltf', data.data.glbFile);
  formData.append('name', data.data.name);
  return dsmOpsAxios.post<Response<Model>>(
    `/v1/projects/${data.projectId}/3d-assets`,
    formData,
  );
};

interface DeleteAssetData {
  projectId: string;
  assetId: string;
}

export const deleteAsset = (data: DeleteAssetData) => {
  return dsmOpsAxios.delete(
    `/v1/projects/${data.projectId}/3d-assets/${data.assetId}`,
  );
};

interface CreateProjectFeatureParams {
  projectId: string;
  data: {
    type:
      | DasEndpointType
      | FeatureListType
      | OtherFeatureType
      | SSSSFeatureType;
    metadata: { [name: string]: any };
  };
}

export const createProjectFeature = (params: CreateProjectFeatureParams) => {
  return dsmOpsAxios.post(
    `/v1/projects/${params.projectId}/features`,
    params.data,
  );
};

interface GetProjectFeatureParams {
  projectId: string;
}

export const getProjectFeature = (params: GetProjectFeatureParams) => {
  return dsmOpsAxios.get<Response<ProjectFeature[]>>(
    `/v1/projects/${params.projectId}/features`,
  );
};

interface UpsertProjectFeatureParams {
  projectId: string;
  data: {
    featureId: string;
    metadata: any;
  };
}

export const upsertProjectFeature = (params: UpsertProjectFeatureParams) => {
  return dsmOpsAxios.put<Response<ProjectFeature[]>>(
    `/v1/projects/${params.projectId}/features/${params.data.featureId}/metadata`,
    params.data.metadata,
  );
};

interface DeleteProjectFeatureParams {
  projectId: string;
  data: {
    featureId: string;
    key?: string;
  };
}

export const deleteProjectFeature = (params: DeleteProjectFeatureParams) => {
  if (params.data.key) {
    return dsmOpsAxios.delete<Response<ProjectFeature>>(
      `/v1/projects/${params.projectId}/features/${params.data.featureId}/metadata/${params.data.key}`,
    );
  }
  return dsmOpsAxios.delete<Response<ProjectFeature>>(
    `/v1/projects/${params.projectId}/features/${params.data.featureId}`,
  );
};

export const getProjectSettings = () => {
  return dsmOpsAxios.get<PagedData<ProjectSetting>>(`v1/projects/settings`);
};

interface CreateProjectSettingParams {
  projectId: string;
  data: {
    userLimit?: {
      admin?: number;
      supervisor?: number;
      viewer?: number;
    };
    areaLimit?: number;
  };
}

export const createProjectSettings = (params: CreateProjectSettingParams) => {
  return dsmOpsAxios.post<Response<ProjectSetting>>(
    `v1/projects/${params.projectId}/settings`,
    params.data,
  );
};

interface UpdateProjectSettingParams extends CreateProjectSettingParams {
  projectSettingId: string;
}

export const updateProjectSettings = (params: UpdateProjectSettingParams) => {
  return dsmOpsAxios.patch<Response<ProjectSetting>>(
    `v1/projects/${params.projectId}/settings/${params.projectSettingId}`,
    params.data,
  );
};
