import { Tab as MuiTab, Tabs as MuiTabs, styled } from '@mui/material';
import { GeneralSettingType } from '../../../../types';
import { SyntheticEvent } from 'react';

const Container = styled('div')``;

const Tabs = styled(MuiTabs)(() => {
  return {};
});

const Tab = styled(MuiTab)(() => {
  return {};
});

const Header = ({
  mode,
  onChange,
}: {
  mode: GeneralSettingType;
  onChange: (mode: GeneralSettingType) => void;
}) => {
  const handleOnChangeTab = (e: SyntheticEvent<Element, Event>, value: any) => {
    onChange(value as GeneralSettingType);
  };

  return (
    <Container>
      <Tabs value={mode} onChange={handleOnChangeTab} centered>
        <Tab
          label="User Limit"
          value="user-limit"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
        />
        <Tab
          label="Area Limit"
          value="area-limit"
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
        />
      </Tabs>
    </Container>
  );
};

export default Header;
