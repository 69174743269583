import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createProjectSettings,
  getProjects,
  getProjectSettings,
  updateProjectSettings,
} from '../../../../apis/projectApi';
import InformationDialog from '../../../../components/Dialog/InformationDialog';
import theme from '../../../../theme';
import { GeneralSettingType, ProjectSetting } from '../../../../types';
import AreaLimit from './AreaLimit';
import Header from './Header';
import UserLimit from './UserLimit';

const Title = styled('div')`
  display: flex;
  justify-content: center;
`;

const ContainerContent = styled(MuiDialogContent)`
  padding: 0px 150px;
  height: 424px;

  & > .errorMessage {
    color: #ff6b00;
    font-size: 0.75rem;
  }
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

const GeneralSettingInfoPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [mode, setMode] = useState<GeneralSettingType>('user-limit');
  const [userLimit, setUserLimit] = useState<{
    [propertyName: string]: any;
  }>({});
  const [areaLimit, setAreaLimit] = useState<number | undefined>();

  const { data: project } = useQuery({
    queryKey: ['get-projects', projectId],
    queryFn: async () => {
      const res = await getProjects({ id: projectId });
      return res.data.data.find((p) => p.id === projectId) || null;
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  const {
    data: projectSettingMap,
    isSuccess,
    refetch: refetchProjectSetting,
  } = useQuery({
    queryKey: ['project-setting'],
    queryFn: async () => {
      const res = await getProjectSettings();
      const rawSettings = res.data.data;

      const localProjectSettingMap: { [index: string]: ProjectSetting } = {};
      rawSettings.forEach((ps) => {
        localProjectSettingMap[ps.projectId] = ps; // or ps.key, adjust as needed
      });

      return localProjectSettingMap;
    },
    refetchOnWindowFocus: false,
  });
  const projectSetting = useMemo(
    () => projectSettingMap?.[projectId ?? ''],
    [projectSettingMap, projectId],
  );

  useEffect(() => {
    if (isSuccess && projectSetting) {
      if (projectSetting.userLimit) {
        setUserLimit(projectSetting.userLimit);
      }
      setAreaLimit(projectSetting.areaLimit);
    }
  }, [isSuccess, projectSetting]);

  const onSave = async () => {
    if (!projectId) return;
    try {
      if (projectSetting) {
        await updateProjectSettings({
          projectId,
          data: { userLimit: userLimit, areaLimit },
          projectSettingId: projectSetting.id,
        });
      } else {
        await createProjectSettings({
          projectId,
          data: { userLimit: userLimit, areaLimit },
        });
      }
      refetchProjectSetting();
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(async () => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const errors = useMemo(() => {
    const errorList: string[] = [];
    const admin = Number(userLimit.admin || 0);
    const supervisor = Number(userLimit.supervisor || 0);
    const viewer = Number(userLimit.viewer || 0);

    if (admin > 2) {
      errorList.push('Admin limit cannot be more than 2');
    }

    if (supervisor + viewer > 30) {
      errorList.push('Supervisor and viewer total cannot exceed 30');
    }

    return errorList;
  }, [userLimit]);

  return (
    <>
      <InformationDialog
        open
        titleNode={{
          center: (
            <Title>
              <span>{project?.name}</span>
            </Title>
          ),
        }}
        closeable
        onClose={() => navigate(-1)}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '900px',
          },
        }}
      >
        <Header
          mode={mode}
          onChange={(val) => {
            setMode(val);
          }}
        />
        <ContainerContent>
          {mode === 'user-limit' && (
            <UserLimit
              userLimit={userLimit}
              onChange={(propertyName: string, value: any) => {
                if (
                  propertyName === 'admin' ||
                  propertyName === 'supervisor' ||
                  propertyName === 'viewer'
                ) {
                  const localUserLimit = { ...userLimit };

                  if (value === '' || value === undefined) {
                    localUserLimit[propertyName] = null;
                  } else {
                    localUserLimit[propertyName] = Number(value);
                  }
                  setUserLimit(localUserLimit);
                }
              }}
            />
          )}
          {mode === 'area-limit' && (
            <AreaLimit
              areaLimit={areaLimit}
              onChange={(val) => {
                setAreaLimit(val);
              }}
            />
          )}
          {errors.map((err, idx) => (
            <div key={idx} className="errorMessage">
              {err}
            </div>
          ))}
        </ContainerContent>

        <DialogActions>
          <DialogButton
            mode="light"
            color="info"
            variant="text"
            style={{ color: theme.color.primary.$100 }}
            onClick={onCancel}
          >
            Cancel
          </DialogButton>
          <DialogButton
            mode="light"
            color="primary"
            variant="contained"
            onClick={onSave}
          >
            Save
          </DialogButton>
        </DialogActions>
      </InformationDialog>
    </>
  );
};

export default GeneralSettingInfoPage;
